<template>
  <div class="infobox">
    <div class="leftbox">
      <div class="lefttitle">
        <span>- 法律条款 -</span>
        <img src="@/assets/image/index/20220513182659.png" alt="" />
      </div>

      <div
        class="leftname"
        @click="selbacolor(0)"
        :class="{ seltxt: ifselect }"
      >
        <span>服务协议</span>
      </div>
      <div
        class="leftname"
        @click="selbacolor(1)"
        :class="{ seltxt: !ifselect }"
      >
        <span>隐私协议</span>
      </div>
    </div>
    <div class="rightbox">
      <div class="h1s">
        <h1>- {{ titletxt }} -</h1>
      </div>
      <div class="righttxt">
        <p>
          <br />
          版本更新日期：2022年06月22日
          <br />
          版本生效日期：2022年07月01日
        </p>
      </div>
      <div>
        <span class="h2s"
          >导言 <img src="@/assets/image/index/20220513182637.png" alt=""
        /></span>
        <br /><br />
        <!-- <span class="span1">欢迎您使用华夏云课堂平台及服务！</span> <br /><br /> -->
        <!-- 隐私协议 -->
        <p v-if="!ifselect" class="textinfo">
          &nbsp;&nbsp;欢迎使用【华夏云课堂】（以下亦简称“我们”）提供的产品和服务！华夏云课堂是一款由北京华夏元道文化科技有限公司（注册地为北京市东城区东直门外香河园北里4号2层201室。以下亦简称“我们”）提供服务的产品。我们深知个人信息对用户的重要性，我们庄严承诺保护使用我们的产品和服务（包括“华夏云课堂网站”、移动客户端、微信小程序，以下统称“华夏云课堂服务”）之用户（以下统称“用户”或“您”）的个人信息及隐私安全。基于上述理念和目的，我们制定华夏云课堂隐私政策（下称“本《隐私政策》”），并向您做出如下提示：<br /><br />
          &nbsp;&nbsp;本《隐私政策》适用于我们提供的所有产品和服务，您在使用我们的产品和/或服务时，我们将会收集、存储、使用、披露和保护您的相关个人信息。您访问我方平台，使用我方平台提供的服务，及未设独立隐私信息保护政策的我方平台网站、服务器、及/或登录相关客户端，均适用本隐私信息保护政策。当您使用我们任何单项服务时，您同意接受本《隐私政策》以及我们在该单项服务中发出的特定隐私信息类政策条款（下列称“特定条款”）的保护，在此情况下特定条款与本《隐私政策》条款同时对您产生效力。如特定条款与本《隐私政策》条款存在同类条款的不一致约定，则在特定条款约束范围内应以特定条款为准。如我们提供的某一单项服务不适用本《隐私政策》的，该服务中会以适当方式明示排除适用本《隐私政策》。<br /><br />
          &nbsp;
          &nbsp;我们希望通过本《隐私政策》向您说明在收集和使用您相关个人信息时对应的处理规则，以及我们为您提供的访问、更正、删除和保护这些个人信息的方式，以便更好的保障您的权益。<br /><br />
          &nbsp;
          &nbsp;本《隐私政策》与您使用的华夏云课堂服务及对应的各项业务功能密切相关，希望您在使用我们的产品和服务前，仔细阅读并确认您已充分理解本《隐私政策》各条款内容，并让您可以按照本《隐私政策》的指引做出您认为适当的选择或操作。如对本政策内容有任何疑问、意见或建议，您可通过华夏云课堂提供的各种联系方式与我们联系。
          您使用或者在我们更新本《隐私政策》后继续使用我们的产品和服务，即意味着您同意本《隐私政策》并同意我们按照本《隐私政策》处理您的相关个人信息。
          <br /><br />
          &nbsp; &nbsp;本《隐私政策》将帮助您了解以下内容：<br /><br />
          &nbsp; &nbsp;一、我们如何收集和使用您的个人信息<br /><br />
          &nbsp; &nbsp;二、我们如何使用COOKIES或同类技术 <br /><br />
          &nbsp; &nbsp;三、我们可能分享、转让和披露的个人信息 <br /><br />
          &nbsp; &nbsp;四、我们如何保留、储存和保护您的个人信息安全 <br /><br />
          &nbsp; &nbsp;五、如何管理您的个人信息 <br /><br />
          &nbsp; &nbsp;六、第三方服务 <br /><br />
          &nbsp; &nbsp;七、通知和修订 <br /><br />
          &nbsp; &nbsp;八、如何联系我们
          <br /><br />
          &nbsp; &nbsp;一、我们如何收集和使用您的个人信息 <br /><br />
          &nbsp;
          &nbsp;我们收集您的个人信息主要是为了您和其他用户能够更容易和更满意地使用华夏云课堂服务，而这些个人信息有助于我们实现这一目标。为方便您快速了解使用本服务涉及到的您的个人信息，为您阐释如下定义并列明可能涉及到的个人信息种类：
          个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。本《隐私政策》中涉及的个人信息包括：
          <br /><br />
          &nbsp; &nbsp;① 个人身份与联络方式
          登录信息：为遵守法律法规的要求，以及向您提供更便捷的服务，在您注册成为用户时，您需要至少提供手机号码以创建账号，并完善相关的网络身份识别信息（如头像、昵称及登录密码等）；如果您仅需使用浏览、搜索等功能，您无需注册成为我们的用户以及提供上述信息。在提供账号信息的过程中，如果您愿意额外补充如下个人信息，将有助于我们向您提供更为个性化的服务：包括您的姓名、生日、性别、收货地址、个人电话号码、电子邮箱、任职公司名称、所在地、身份证、护照等信息，以实现华夏云课堂账号的身份验证，便于您学习及证书的获取。如果您不提供该等信息，不会影响您享受华夏云课堂的基本功能。
          如您选择授权使用第三方账号登录时，我们会从第三方合作机构（如微信登录）获取您共享的账号信息（如头像、昵称、地区、性别等信息）与您的账号进行绑定用于快捷登录，我们会依据与第三方的约定，在符合相关法规的前提下，使用您的个人信息。
          通讯录信息：我们会在获得您明确同意后读取您手机通讯录的信息。
          <br /><br />
          &nbsp; &nbsp;② 设备信息及日志信息
          使用目的：为保障正常的使用、运行、及账号安全，改进及优化服务体验，同时为提供个性化用户体验，我们将收集您的设备信息、日志信息。
          信息说明：我们先将收集您的硬件型号、设备MAC地址、操作系统类型、软件列表唯一设备识别码（如IMEI/android
          ID/IDFA/OPENUDID/GUID、SIM卡IMSI信息等在内的描述个人常用设备基本情况的信息）、华夏云课堂账号及密码、IP地址、邮箱地址及与前述有关的密码、口令、口令保护答案等。
          <br /><br />
          &nbsp; &nbsp;③ 地理位置信息
          使用目的：为了更好的为您提供服，经您授权，我们会收集您的地理位置信息。
          信息说明：经您授权，我们将收集您的地理位置信息。
          <br /><br />
          &nbsp; &nbsp;④ 相机及相册权限
          使用目的：当您上传头像、保存证书、提交证书等拍摄或编辑图片或视频时，经您授权，我们会开启您的相机及相册权限
          信息说明：上述均为敏感权限，我们仅在您使用时对功能所需时开启。
          <br /><br />
          &nbsp; &nbsp;⑤ 获取本地文件
          使用目的：为了更好的使用华夏云课堂，方便您的使用需要获取您的当前安装包进行识别更新，以及相关的资质内容上传。
          信息说明：经您授权后，我们将会获取您的本地文件。
          <br /><br />
          &nbsp; &nbsp;⑥ 获取用户偏好选择使用目的：为了更好的为您提供服务，经您授权，若选择了相关的偏好设置时，专属推荐将会根据您所选择的偏好进行推荐。
          <br /><br />
          &nbsp; &nbsp;⑦ 帮您完成支付
          您在华夏云课堂上进行订单支付时，，您可以选择华夏云课堂的第三方合作机构（如微信支付、支付宝等合作方）所提供的服务进行支付。我们需要收集您的华夏云课堂订单信息、对账信息，以确认您的支付指令并帮助您完成支付。
          <br /><br />&nbsp;&nbsp;当您在使用编辑头像（照片浏览）、私信（发送图片、发送文件）、提交作业（上传图片、上传文件）功能时，我们会收集您主动提供的上述图片、文件内容信息，同时我们还将为便于您发送图片和文件而经过您的同意后访问您的手机存储权限。同时，您也可以随时通过您的设备系统设置相关功能设置页面开启/取消该权限。您开启该权限即视为您授权我们可以访问、获取、收集、使用您的该等个人信息；当您取消该授权后，我们将不再收集该信息，也无法再为您提供上述与之对应的服务；但除非您依照法律的规定删除了您的个人信息，否则您的取消行为不会影响我们基于您之前的授权进行的对您个人信息的处理、存储。
          个人敏感信息是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息，本《隐私政策》中涉及的个人敏感信息包括：
          <br /><br />
          &nbsp; &nbsp;①
          个人财产信息（包括华夏云课堂内的交易或消费记录或虚拟财产信息等）；
          <br /><br />
          &nbsp; &nbsp;②
          网络身份标识信息（包括华夏云课堂账号及密码、IP地址、邮箱地址及与前述有关的密码、口令、口令保护答案）；
          <br /><br />
          &nbsp; &nbsp;③ 其他信息（如个人电话号码、身份证信息等） <br /><br />
          &nbsp; &nbsp;我们会出于以下目的，收集和使用您以下类型的个人信息：
          <br /><br />
          &nbsp; &nbsp;(一) 您须授权我们收集和使用您个人信息的情形：
          <br /><br />
          &nbsp;
          &nbsp;我们的产品与/或服务包括一些核心功能，这些功能包含了实现在华夏云课堂进行线上学习所必须的功能及保障交易安全所必须的功能。我们可能会收集、保存和使用下列与您有关的信息才能实现上述这些功能。如果您不提供相关信息，您将无法享受我们提供的产品与/或服务。这些功能包括：
          <br /><br />
          &nbsp; &nbsp;1、用户注册及登录 <br /><br />
          &nbsp;
          &nbsp;为便于我们为您提供华夏云课堂完整的服务，您需要提供基本注册或登录个人信息，包括手机号码，并创建您的账号、用户名和密码。注册成功后，您的用户名即为您的华夏云课堂账号。
          <br /><br />
          &nbsp;
          &nbsp;如果您使用微信、QQ或apple账号关联登录华夏云课堂时，我们会收集您的个人电话号码、头像、昵称、性别，此种情形下，会强制关联您的个人电话号码作为您的华夏云课堂账号，您可通过所关联的微信或输入的个人电话号码及华夏云课堂发送的收集验证码登录华夏云课堂。
          <br /><br />
          &nbsp;
          &nbsp;依照相关法律法规的要求或者特定功能及服务需要，您在使用其他特定功能及服务前，可能需要您提供其他个人信息（例如姓名、身份证及其他身份证明信息等）。如果您不提供上述个人信息，我们将不能向您提供相关的功能及服务。
          <br /><br />
          &nbsp; &nbsp;2、在线使用功能 <br /><br />
          &nbsp;
          &nbsp;（1）对于华夏云课堂内的课程内容，我们为您提供了在线使用功能（根据每一个具体虚拟数字商品的在线使用功能可能略有不同，以华夏云课堂内显示功能为准），您可以在相应界面中进行评论、留言、发表答案、收藏、分享，为此我们需要收集您的华夏云课堂账号、软件使用记录以实现前述功能。
          <br /><br />
          &nbsp; &nbsp;（2）练习功能 <br /><br />
          &nbsp;
          &nbsp;我们为您提供在线课后练习功能，您可以浏览其他用户的学习心得、留言及评论，可以对其他用户发布的内容进行评论和点赞，为此我们需要收集您的华夏云课堂账号、软件使用记录以实现前述功能。
          <br /><br />
          &nbsp;
          &nbsp;我们将收集您上传、发布或形成的个人信息（头像、姓名或昵称或个性签名、身份）、练习答案等内容向其他学员展示。
          <br /><br />
          &nbsp;
          &nbsp;请注意，您公开发布的信息中可能会涉及您或者他人的个人信息甚至个人敏感信息。请您更加谨慎的考虑，是否在线使用我们服务时，共享或者公开相关信息。
          <br /><br />
          &nbsp; &nbsp;3、商品下单及购买 <br /><br />
          &nbsp;
          &nbsp;华夏云课堂内的付费类数字及实体商品，包括但不限于：课程（课程延期）费、书籍费、（模拟）考试费、证书费、照片打印费，需要您支付相应金额的货币下单并支付完成后使用，为实现付费类商品的下单功能，我们需要获取您的得到账号，下单完成后，您可以选择使用第三方支付机构（包括在线转账支付、微信支付、支付宝支付等，以下简称“支付机构”）所提供的支付服务，支付功能需收集您的华夏云课堂账号及姓名，我们需要将您的订单号和交易金额与支付机构共享以实现确认您的支付指令并完成支付。如果您购买线下课程、讲座、现场直播课等产品，您还需提供学员的姓名、手机号、邮箱等信息，用于学员登记和课程相关信息的接受。
          <br /><br />
          &nbsp; &nbsp;4、客服与售后服务 <br /><br />
          &nbsp;
          &nbsp;当您与我们联系时，我们可能会保存您的通信/通话记录和内容或您留下的联系方式或通过调用您的通讯录信息，以便与您联系或帮助您解决问题，或记录相关问题的处理方案及结果。为确认交易状态及为您提供售中售后与争议解决服务，我们会通过您基于交易所选择的交易对象、支付机构、物流公司等收集与交易进度相关的您的交易、支付、物流信息，或将您的交易信息共享给上述服务提供者。
          <br /><br />
          &nbsp; &nbsp;5、保障交易安全所必须的功能 <br /><br />
          &nbsp;
          &nbsp;为提高您使用我们的产品与/或服务时系统的安全性，更准确地预防钓鱼网站欺诈和保护账户安全，我们可能会通过了解您的浏览信息、订单信息、您常用的软件信息、设备信息及我们关联公司、合作伙伴取得您授权或依据法律共享所获得的信息来判断您的账号风险及交易风险、进行身份验证、检测及防范安全事件，并可能会记录一些我们认为有风险的链接（“URL”）；我们也会收集您的设备信息对于华夏云课堂系统问题进行分析、统计流量并排查可能存在的风险、在您选择向我们发送异常信息时予以排查。
          <br /><br />
          &nbsp; &nbsp;(二) 您可自主选择提供的个人信息的情形 <br /><br />
          &nbsp;
          &nbsp;为向您提供更便捷、更优质的产品和服务，提升您的体验，我们在向您提供以下附加服务中，可能会收集和使用您的个人信息。如果您不提供这些信息，不会影响您浏览、搜索、购买等基本服务，但是您可能无法获得这些附加服务给您带来的用户体验。
          <br /><br />
          &nbsp; &nbsp;1、您参加运营和互动活动 <br /><br />
          &nbsp;
          &nbsp;若您参加我方平台的运营和互动活动，如抽奖、参加竞猜、答题、问卷调查时，可能需要您主动提交有关信息。例如对特定活动所领取的红包进行提现、奖品兑现和向税务机构完税，您可能需要提交身份证信息。若奖品或者活动礼品需要寄送，您还需要提供地址信息。
          <br /><br />
          &nbsp; &nbsp;2、学习营服务 <br /><br />
          &nbsp;
          &nbsp;当您报名课程的学习营服务后，您可以通过同学信息认识报名了相同学习营的其他同学，为了更好的使用上述功能，我们将依据您的授权将收集您上传、发布或形成的个人信息（行业、职位、职业方向、公司、工作年限）向其他学员展示。如果您不提供这些信息，您可能无法使用相关功能，但不影响您对其他功能与服务的正常使用。
          <br /><br />
          &nbsp; &nbsp;3、为您提供内容推荐展示 <br /><br />
          &nbsp;
          &nbsp;为了更好地为您提供内容推荐展示，对搜索结果进行优化，了解产品对不同硬件设备适配性、确保操作环境安全、识别账号异常状态用途，我们会收集关于您对我们产品或服务使用方式的信息，并将这些信息进行关联，这些信息包括：
          <br /><br />
          &nbsp;
          &nbsp;（1）设备信息：我们会根据您在软件安装及/或使用中授予的具体操作，接收并记录您所使用的设备相关信息（例如设备型号、操作系统版本、设备设置、移动设备识别码、网络设备信息、必要的移动应用列表信息软硬件及设备、设备环境信息）、设备所在位置相关信息（包括您的授权的GPS位置以及WLAN接入点和基站传感器信息)。
          <br /><br />
          &nbsp; &nbsp;（2）
          日志信息：当您使用我们的网站或客户端提供的产品或服务时，我们会自动收集您对我们服务的详细使用情况，作为有关网络日志保存。包括您的搜索和浏览记录、关注、播放记录、访问量、播放时长、IP地址、WIFI网络、电信运营商网络、使用的语言、访问日期和时间。
          <br /><br />
          &nbsp;
          &nbsp;在您选择“订阅”的前提下，为向您提供更便捷的信息展示及推送服务，我们会以短信、弹窗提示及平台通知的形式向您展示、推送您的“订阅信息”。我们努力保障您的浏览体验。
          <br /><br />
          &nbsp; &nbsp;(三) 4、数据统计与产品优化 <br /><br />
          &nbsp;
          &nbsp;为了通过数据统计对产品进行优化，我们需要获得您的设备MAC地址，唯一设备识别码、华夏云课堂账号、性别、出生年份、行业、职业、职务、软件使用记录、点击记录、IP地址。
          <br /><br />
          &nbsp; &nbsp;5、基于相机、相册权限的附加服务 <br /><br />
          &nbsp;
          &nbsp;您可以在开启相机、相册权限后使用该功能，实现头像上传、考试报名照片上传、意见反馈等功能。
          <br /><br />
          &nbsp; &nbsp;6、基于日历权限的附加服务 <br /><br />
          &nbsp;
          &nbsp;我们提供的尚未开播的课程产品中，您可以在开启日历权限后，实现预约各类课程服务和提醒功能。
          <br /><br />
          &nbsp; &nbsp;7、基于SD card访问权限的附加服务（仅针对安卓系统用户）
          <br /><br />
          &nbsp; &nbsp;我们提供的虚拟数字产品缓存服务可在您开启SD
          card访问权限下实现。 <br /><br />
          &nbsp; &nbsp;8、基于发送短信的附加功能 <br /><br />
          &nbsp;
          &nbsp;您可以将华夏云课堂的优质课程信息通过短信、微信等第三方通讯工具发送给您的好友。
          <br /><br />
          &nbsp; &nbsp;9、基于拨打电话的附加功能 <br /><br />
          &nbsp;
          &nbsp;依据您选择的课程内容及学习情况，我们可能需要通过拨打电话进行活动告知（如直播提醒、课程提醒等）和服务调研，以便为您提供更优质的服务，同时帮助我们改善现有服务或设计新的服务内容。
          <br /><br />
          &nbsp; &nbsp;(三) 例外情形 <br /><br />
          &nbsp;
          &nbsp;请您注意，根据国家相关法律法规的规定，以下情形中，华夏云课堂收集、使用您的个人信息无需征得您的授权同意：
          <br /><br />
          &nbsp; &nbsp;（1）与国家安全、国防安全有关的； <br /><br />
          &nbsp; &nbsp;（2）与公共安全、公共卫生、重大公共利益有关的；
          <br /><br />
          &nbsp; &nbsp;（3）与犯罪侦查、起诉、审判和判决执行等有关的；
          <br /><br />
          &nbsp;
          &nbsp;（4）出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
          <br /><br />
          &nbsp;
          &nbsp;（5）所收集的个人信息是个人信息主体或监护人自行向社会公众公开的；
          <br /><br />
          &nbsp; &nbsp;(6)
          从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；
          <br /><br />
          &nbsp; &nbsp;(7) 根据您的要求签订合同所必需的； <br /><br />
          &nbsp;
          &nbsp;(8)用于维护所提供的产品与/或服务的安全稳定运行所必需的，例如发现、处置产品与/或服务的故障；
          <br /><br />
          &nbsp; &nbsp;(9)为合法的新闻报道所必需的； <br /><br />
          &nbsp;
          &nbsp;(10)学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；
          <br /><br />
          &nbsp; &nbsp;(11)法律法规规定的其他情形。

          <br /><br />
          &nbsp; &nbsp;(四) 我们从第三方获得您个人信息的情形 <br /><br />
          &nbsp;
          &nbsp;我们可能从第三方获取您授权共享的账户信息（头像、昵称、位置信息、性别等），并在您同意本《隐私政策》后将您的第三方账户与您的华夏云课堂账户绑定，使您可以通过第三方账户直接登录并使用我们的产品与/或服务。我们会将依据与第三方的约定、对个人信息来源的合法性进行确认后，在符合相关法律和法规规定的前提下，使用您的这些个人信息。
          <br /><br />
          &nbsp; &nbsp;(五) 您个人信息使用的规则 <br /><br />
          &nbsp;
          &nbsp;1、我们会根据本《隐私政策》的约定并为实现我们的产品与/或服务功能对所收集的个人信息进行使用。
          <br /><br />
          &nbsp;
          &nbsp;2、在收集您的个人信息后，我们将通过技术手段对数据进行去标识化处理，去标识化处理的信息将无法识别主体。请您了解并同意，在此情况下我们有权使用已经去标识化的信息；并在不透露您个人信息的前提下，我们有权对用户数据库进行分析并予以商业化的利用。
          <br /><br />
          &nbsp;
          &nbsp;3、请您注意，您在使用我们的产品与/或服务时所提供的所有个人信息，除非您删除或通过系统设置拒绝我们收集，否则将在您使用我们的产品与/或服务期间持续授权我们使用。在您注销账号时，我们将停止使用并删除您的个人信息。
          <br /><br />
          &nbsp;
          &nbsp;4、我们会对我们的产品与/或服务使用情况进行统计，并可能会与公众或第三方共享这些统计信息，以展示我们的产品与/或服务的整体使用趋势。但这些统计信息不包含您的任何身份识别信息。
          <br /><br />
          &nbsp;
          &nbsp;5、当我们展示您的个人信息时，我们会采用包括内容替换、匿名处理方式对您的信息进行脱敏，以保护您的信息安全。
          <br /><br />
          &nbsp;
          &nbsp;6、当我们要将您的个人信息用于本《隐私政策》未载明的其它用途时，或基于特定目的收集而来的信息用于其他目的时，会通过您主动做出勾选的形式事先征求您的同意。
          <br /><br />
          &nbsp; &nbsp;二、我们如何使用 Cookies 和同类技术 <br /><br />
          &nbsp; &nbsp;(一)
          为实现您联机体验的个性化需求，使您获得更轻松的访问体验。我们会在您的计算机或移动设备上发送一个或多个名为Cookies的小数据文件，指定给您的Cookies
          是唯一的，它只能被将Cookies发布给您的域中的Web服务器读取。我们向您发送Cookies是为了简化您重复登录的步骤、帮助判断您的登录状态以及账户或数据安全。

          <br /><br />
          &nbsp; &nbsp;(二) 我们不会将 Cookies
          用于本《隐私政策》所述目的之外的任何用途。您可根据自己的偏好管理或删除
          Cookies。您可以清除计算机上保存的所有
          Cookies，大部分网络浏览器会自动接受Cookies，但您通常可根据自己的需要来修改浏览器的设置以拒绝
          Cookies；另外，您也可以清除软件内保存的所有Cookies。但如果您这么做，您可能需要在每一次访问华夏云课堂时亲自更改用户设置，而且您之前所记录的相应信息也均会被删除，并且可能会对您所使用服务的安全性有一定影响。
          <br /><br />
          &nbsp; &nbsp;（三）网站信标和像素标签 <br /><br />
          &nbsp; &nbsp; 除 Cookie
          外，我们还会在网站上使用网站信标和像素标签等其他同类技术。例如，我们向您发送的电子邮件可能含有链接至我们网站内容的点击
          URL。如果您点击该链接，我们则会跟踪此次点击，帮助我们了解您的产品或服务偏好并改善客户服务。网站信标通常是一种嵌入到网站或电子邮件中的透明图像。借助于电子邮件中的像素标签，我们能够获知电子邮件是否被打开。如果您不希望自己的活动以这种方式被追踪，则可以随时从我们的寄信名单中退订。
          <br /><br />
          &nbsp; &nbsp;（四）Do Not Track（请勿追踪） <br /><br />
          &nbsp; &nbsp; 很多网络浏览器均设有 Do Not Track
          功能，该功能可向网站发布 Do Not Track
          请求。目前，主要互联网标准组织尚未设立相关政策来规定网站应如何应对此类请求。但如果您的浏览器启用了
          Do Not Track，那么我们的所有网站都会尊重您的选择。
          <br /><br />
          &nbsp; &nbsp;三、我们如何共享、转让、公开披露您的个人信息 <br /><br />
          &nbsp; &nbsp;（一）共享 <br /><br />
          &nbsp;
          &nbsp;1、我们不会与华夏云课堂及其合作伙伴以外的任何公司、组织和个人共享您的个人信息，但以下情况除外：
          <br /><br />
          &nbsp; &nbsp;（1）事先获得您明确的同意或授权； <br /><br />
          &nbsp;
          &nbsp;（2）根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况下进行提供；
          <br /><br />
          &nbsp;
          &nbsp;（3）在法律法规允许的范围内，为维护华夏云课堂、华夏云课堂的关联方或合作伙伴、您或其他华夏云课堂用户或社会公众利益、财产或安全免遭损害而有必要提供；
          <br /><br />
          &nbsp; &nbsp;（4）
          只有共享您的个人信息，才能实现我们的产品与/或服务的核心功能或提供您需要的服务；
          <br /><br />
          &nbsp; &nbsp;（5）应您需求为您处理您与他人的纠纷或争议； <br /><br />
          &nbsp;
          &nbsp;（6）符合与您签署的相关协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；
          <br /><br />
          &nbsp; &nbsp;（7）基于学术研究而使用； <br /><br />
          &nbsp; &nbsp;（8）基于符合法律法规的社会公共利益而使用。 <br /><br />
          &nbsp; &nbsp;2、我们可能会将您的个人信息与我们的关联方共享。
          <br /><br />
          &nbsp;
          &nbsp;但我们只会共享必要的个人信息，且受本《隐私政策》中所声明目的的约束。我们的关联方如要改变个人信息的处理目的，将再次征求您的授权同意。
          <br /><br />
          &nbsp; &nbsp;3、我们可能会与授权合作伙伴共享。 <br /><br />
          &nbsp;
          &nbsp;您授权同意我们可能委托授权合作伙伴为您提供某些服务或代表我们履行职能，我们仅会出于本隐私信息保护政策声明的合法、正当、必要、特定、明确的目的共享您的信息，授权合作伙伴只能接触到为其履行职责所需信息，且不得将此信息用于其他任何目的。
          <br /><br />
          &nbsp; &nbsp;4.协助处理争议 <br /><br />
          &nbsp;
          &nbsp;为了遵守法律、执行或适用我们的使用条件和其他协议，或者为了保护华夏云课堂、您或其他华夏云课堂用户的权利及其财产或安全，比如为防止欺诈等违法活动和减少信用风险，而与其他公司和组织交换信息。不过,这并不包括违反本《隐私政策》中所作的承诺而为获利目的出售、出租、共享或以其它方式披露的个人信息。
          <br /><br />
          &nbsp; &nbsp;（二）转让 <br /><br />
          &nbsp;
          &nbsp;我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：
          <br /><br />
          &nbsp; &nbsp;1、事先获得您明确的同意或授权； <br /><br />
          &nbsp;
          &nbsp;2、根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况进行提供；
          <br /><br />
          &nbsp;
          &nbsp;3、符合与您签署的相关协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；
          <br /><br />
          &nbsp;
          &nbsp;4、在涉及合并、收购、资产转让或类似的交易时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本《隐私政策》的约束，否则,我们将要求该公司、组织重新向您征求授权同意。
          <br /><br />
          &nbsp; &nbsp;（三）公开披露 <br /><br />
          &nbsp;
          &nbsp;我们仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，才会公开披露您的个人信息：
          <br /><br />
          &nbsp;
          &nbsp;1、根据您的需求，在您明确同意的披露方式下披露您所指定的个人信息；
          <br /><br />
          &nbsp;
          &nbsp;2、根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您个人信息的情况下，我们可能会依据所要求的个人信息类型和披露方式公开披露您的个人信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求必须出具与之相应的法律文件，如传票或调查函。我们坚信，对于要求我们提供的信息，应该在法律允许的范围内尽可能保持透明。我们对所有的请求都进行了慎重的审查，以确保其具备合法依据，且仅限于执法部门因特定调查目的且有合法权利获取的数据。在法律法规许可的前提下，我们披露的文件均在加密密钥的保护之下。
          <br /><br />
          &nbsp; &nbsp;四、我们如何保留、储存和保护您的个人信息安全 <br /><br />
          &nbsp;
          &nbsp;我们非常重视个人信息安全，并采取一切合理可行的措施，保护您的个人信息：
          <br /><br />
          &nbsp; &nbsp;(一) 信息存储的地点 <br /><br />
          &nbsp;
          &nbsp;我们在中华人民共和国境内运营中收集和产生的个人信息，存储在中国境内，以下情形除外：
          <br /><br />
          &nbsp; &nbsp;1.法律法规有明确规定； <br /><br />
          &nbsp; &nbsp;2.获得您的明确授权； <br /><br />
          &nbsp;
          &nbsp;针对以上情形，我们会确保依据本隐私政策对您的个人信息提供足够的保护。
          <br /><br />
          &nbsp; &nbsp;(二) 信息存储期限 <br /><br />
          &nbsp;
          &nbsp;我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非法律有强制的存留要求。而我们判断前述期限的标准包括：
          <br /><br />
          &nbsp;
          &nbsp;1、完成与您相关的服务目的、维护相应服务及业务记录、应对您可能的查询或投诉；
          <br /><br />
          &nbsp; &nbsp;2、保证我们为您提供服务的安全和质量； <br /><br />
          &nbsp; &nbsp;3、您是否同意更长的留存期间； <br /><br />
          &nbsp; &nbsp;4、是否存在保留期限的其他特别约定。 <br /><br />
          &nbsp;
          &nbsp;在您的个人信息超出保留期限后，我们会根据适用法律的要求删除您的个人信息，或使其匿名化处理。
          <br /><br />
          &nbsp; &nbsp;(三) 信息删除和匿名化处理 <br /><br />
          &nbsp;
          &nbsp;如相关服务决定停止运营时，我们将在相关服务停止运营后停止继续收集您的个人信息。我们将以公告形式将停止运营通知向您送达。对已持有的您的个人信息将进行删除或匿名化处理。
          <br /><br />
          &nbsp; &nbsp;(四) 数据安全技术措施 <br /><br />
          &nbsp;
          &nbsp;我们会采用符合业界标准的安全防护措施，包括建立合理的制度规范、安全技术来防止您的个人信息遭到未经授权的访问使用、修改,避免数据的损坏或丢失。
          华夏云课堂网络服务采取了传输层安全协议等加密技术，通过https等方式提供浏览服务，确保用户数据在传输过程中的安全；采取加密技术对用户个人信息进行加密保存，并通过隔离技术进行隔离。
          在个人信息使用时，例如个人信息展示、个人信息关联计算，我们会采用包括内容替换、多种数据脱敏技术增强个人信息在使用中安全性。
          <br /><br />
          &nbsp; &nbsp;(五) 为保护个人信息采取的其他安全措施 <br /><br />
          &nbsp;
          &nbsp;通过建立数据安全管理规范、数据安全开发规范来管理规范个人信息的存储和使用。
          通过信息接触者保密协议、监控机制来对数据进行全面安全控制。
          加强安全意识。我们还会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。
          <br /><br />
          &nbsp; &nbsp;(六) 保密义务 <br /><br />
          &nbsp;
          &nbsp;我们仅允许有必要知晓这些信息的华夏云课堂及关联方员工、合作伙伴访问个人信息，并为此设置了严格的访问权限控制和监控机制。我们同时要求可能接触到您个人信息的所有人员履行相应的保密义务。如果未能履行这些义务，可能会被追究法律责任或被中止与华夏云课堂的合作关系。
          我们会采取一切合理可行的措施，确保未收集无关的个人信息。
          <br /><br />
          &nbsp; &nbsp;(七) 提示 <br /><br />
          &nbsp; &nbsp;1.
          联网并非绝对安全的环境，而且电子邮件、即时通讯、社交软件等与其他用户的交流方式无法确定是否完全加密，我们建议您使用此类工具时请使用复杂密码，并注意保护您的个人信息安全。
          <br /><br />
          &nbsp; &nbsp;2.
          互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。
          <br /><br />
          &nbsp; &nbsp;(八) 安全事件处置 <br /><br />
          &nbsp;
          &nbsp;在通过华夏云课堂网站与第三方进行商品或服务的交易时，您不可避免的要向交易对方或潜在的交易对方披露自己的个人信息，如联络方式或者邮政地址等。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。
          在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们同时将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。
          <br /><br />
          &nbsp; &nbsp;五、如何管理您的个人信息 <br /><br />
          &nbsp;
          &nbsp;我们鼓励您更新和修改您的个人信息以使其更准确有效，也请您理解，您更正、删除、撤回授权或停止使用华夏云课堂服务的决定，并不影响我们此前基于您的授权而开展的个人信息处理。除法律法规另有规定，当您更正、删除您的个人信息或申请注销账号时，我们可能不会立即从备份系统中更正或删除相应的个人信息，但会在备份更新时更正或删除这些个人信息。
          <br /><br />
          &nbsp; &nbsp;您可以通过以下方式来管理您的个人信息： <br /><br />
          &nbsp; &nbsp;（一）访问、更正和删除您的个人信息 <br /><br />
          &nbsp;
          &nbsp;您能通过华夏云课堂服务访问您的个人信息，并根据对应个人信息的管理方式自行完成或要求我们进行访修改、补充和删除。我们将采取适当的技术手段或提供提交申请的联系渠道，尽可能保证您可以访问、更新和更正自己的个人信息或使用华夏云课堂服务时提供的其他个人信息。
          <br /><br />
          &nbsp; &nbsp;1． 访问您的个人信息 <br /><br />
          &nbsp;
          &nbsp;您有权访问您的个人信息，法律法规规定的例外情况除外。如果您想行使数据访问权，可以通过以下方式自行访问：
          账户信息——如果您希望访问或编辑您的账户中的个人资料信息中的收货信息和更换手机号，您可以通过访问【我的】或【个人中心】执行此类操作。
          如果您无法通过上述操作访问这些个人信息，您可以发送电子邮件至【class@hxph.com.cn】。我们将在30天内回复您的访问请求。
          <br /><br />
          &nbsp; &nbsp;2． 更正或补充您的个人信息 <br /><br />
          &nbsp;
          &nbsp;当您发现我们处理的关于您的个人信息有错误时，您有权要求我们做出更正。您可以通过“（一）访问您的个人信息”中罗列的方式提出更正申请。
          如果您无法通过上述操作更正这些个人信息，您可以发送电子邮件至【class@hxph.com.cn】。我们将在30天内回复您的更正请求。
          <br /><br />
          &nbsp; &nbsp;3． 删除您的个人信息 <br /><br />
          &nbsp; &nbsp;在以下情形中，您可以向我们提出删除个人信息的请求：
          <br /><br />
          &nbsp; &nbsp;①　如果我们处理个人信息的行为违反法律法规； <br /><br />
          &nbsp; &nbsp;②　如果我们处理个人信息的行为违反了与您的约定；
          <br /><br />
          &nbsp; &nbsp;③　如果我们收集、使用您的个人信息，却未征得您的同意；
          <br /><br />
          &nbsp; &nbsp;④　如果您注销了华夏云课堂账号时； <br /><br />
          &nbsp; &nbsp;⑤　如果我们终止服务及运营时； <br /><br />
          &nbsp;
          &nbsp;⑥　若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。当您从我们的服务中删除信息后，我们可能不会立即备份系统中删除相应的信息，但会在备份更新时删除这些信息。
          <br /><br />
          &nbsp; &nbsp;4． 您有权注销您的账号 <br /><br />
          &nbsp;
          &nbsp;①　您可以通过app中“设置-账号安全-注销账户”实现注销账户功能，申请注销您的账号。经您的授权，进行相关操作后将会注销您的账号。
          <br /><br />
          &nbsp;
          &nbsp;②　当您注销账号后，您将无法再以该账号登录和使用我们的产品与服务；且该账号在华夏云课堂及旗下的其他产品与服务使用期间已产生的但未消耗完毕的权益及未来的逾期利益等全部权益将被清除；该账号下的内容、信息、数据、记录等将会被删除或匿名化处理（但法律法规另有规定或监管部门另有要求的除外，如依据《中华人民共和国网络安全法》规定，您的网络操作日志将至少保留六个月的时间）；华夏云课堂账号注销完成后，将无法恢复。
          <br /><br />
          &nbsp;
          &nbsp;③　如果您在处置您的个人信息时有任何疑问，您可以通过本隐私权政策第八条“联系我们”中公示的联系方式与我们沟通解决。
          <br /><br />
          &nbsp; &nbsp;（二）公开与分享 <br /><br />
          &nbsp;
          &nbsp;我们的多项服务可让您不仅与您的社交网络、也与使用该服务的所有用户公开分享您的相关个人信息，例如，您在华夏云课堂服务中所上传或发布的个人信息、您对其他人上传或发布的个人信息作出的回应，通过电子邮件或在华夏云课堂服务中不特定用户可见的公开区域内上传或公布您的个人信息，以及包括与这些个人信息有关的位置数据和日志信息。只要您不删除您所公开或共享的个人信息，有关个人信息可能一直留存在公众领域；即使您删除共享个人信息，有关个人信息仍可能由其他用户或不受我们控制的第三方独立地缓存、复制或储存，或由其他用户或该等第三方在公众领域保存。如您将个人信息通过上述渠道公开或共享，由此造成您的个人信息泄露，我们不承担责任。因此，我们提醒并请您慎重考虑是否通过上述渠道公开或共享您的个人信息。
          <br /><br />
          &nbsp; &nbsp;（三）改变您授权同意的范围 <br /><br />
          &nbsp;
          &nbsp;您总是可以选择是否披露个人信息。有些个人信息是使用华夏云课堂服务所必需的，但大多数其他个人信息的提供是由您决定的。您可以通过删除个人信息、关闭设备功能等方式改变您授权我们继续收集个人信息的范围或撤回您的授权。
          当您撤回授权后，我们无法继续为您提供撤回授权所对应的服务，也不再处理您相应的个人信息。但您撤回授权的决定，不会影响此前基于您的授权而开展的个人信息处理。
          <br /><br />
          &nbsp; &nbsp;六、第三方服务 <br /><br />
          &nbsp;
          &nbsp;为保障华夏云课堂网站及APP的稳定运行、提供相关服务或实现相关功能，华夏云课堂服务可能链接至第三方提供的社交媒体、软件开发包（SDK）或其他服务（包括网站或其他服务形式）以实现前述目的。在符合相关法律法规的前提下，我们可能会允许接入的第三方收集用户信息，用于向用户提供服务。具体包括：
          <br /><br />
          &nbsp;
          &nbsp;（一）您可利用“分享”键将某些内容分享到第三方平台，或您可利用第三方服务登录华夏云课堂服务。这些功能可能会收集您的个人信息（包括您的日志信息），并可能在您的电脑装置COOKIES，从而正常运行上述功能；
          <br /><br />
          &nbsp;
          &nbsp;（二）我们通过广告或我们服务的其他方式向您提供链接，使您可以接入第三方的服务或网站；
          <br /><br />
          &nbsp;
          &nbsp;（三）其他接入第三方服务的情形。为实现本政策中声明的目的，我们可能会接入第三方服务商提供的SDK或其他类似的应用程序，并将我们依照本政策收集的您的某些信息共享给该等第三方服务商，以便提供更好的客户服务和用户体验。目前，我们接入的第三方服务商主要包括以下几种类型：
          <br /><br />
          &nbsp; &nbsp;1、第三方SDK
          “环信”，主要用于APP组建聊天室，并进行聊天。第三方SDK提供方为北京易掌云峰科技有限公司；
          <br /><br />
          &nbsp;
          &nbsp;2、第三方SDK“个推”,用于推送相关服务信息，包括手机厂商Push推送，需要获取您手机的IMEI及MAC，第三方SDK提供方为每日互动股份有限公司（此产品的隐私政策条款，可参考
          每日互动股份有限公司隐私协议 ）。
          <br /><br />
          &nbsp; &nbsp;对于我们共享的个人信息清单明细，您可以查阅一下 <a href="/detailedList">《华夏云课堂第三方信息共享清单》</a> 。 <br /><br />

          &nbsp; &nbsp;七、通知和修订 <br /><br />
          &nbsp;
          &nbsp;1、为给您提供更好的服务以及随着华夏云课堂业务的发展，本《隐私政策》也会随之更新。但未经您明确同意，我们不会削减您依据本《隐私政策》所应享有的权利。我们会通过在华夏云课堂网站、华夏云课堂移动端上发出更新版本并在生效前通过网站公告或以其他适当方式提醒您相关内容的更新，也请您访问华夏云课堂以便及时了解最新的隐私政策。
          <br /><br />
          &nbsp;
          &nbsp;2、对于重大变更，我们还会提供更为显著的通知（我们会通过包括但不限于邮件、短信或在浏览页面做特别提示等方式，说明隐私政策的具体变更内容）。
          <br /><br />
          &nbsp;
          &nbsp;3、您与华夏云课堂签署的本政策所列明的条款，并不能完全涵盖您与华夏云课堂所有的关于个人信息的权利和义务。因此，华夏云课堂公布其他声明、各形式规则均视为本政策之补充条款，为本政策不可分割的组成部分，与本协议具有同等法律效力。
          <br /><br />
          &nbsp;
          &nbsp;4、未经您明确同意，我们不会限制您按照本隐私政策所应享有的权利。我们会在华夏云课堂平台专门链接上发布对隐私政策所做的任何变更和更新。
          对于重大变更，我们还会提供更为显著的通知（包括我们会通过我方平台公示的方式进行通知甚至向您提供弹窗提示）。
          <br /><br />
          &nbsp; &nbsp;本《隐私政策》所指的重大变更包括但不限于： <br /><br />
          &nbsp;
          &nbsp;（1）我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；
          <br /><br />
          &nbsp;
          &nbsp;（2）我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；
          <br /><br />
          &nbsp; &nbsp;（3）个人信息共享、转让或公开披露的主要对象发生变化；
          <br /><br />
          &nbsp;
          &nbsp;（4）您参与个人信息处理方面的权利及其行使方式发生重大变化；
          <br /><br />
          &nbsp;
          &nbsp;（5）我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；
          <br /><br />
          &nbsp; &nbsp;（6）个人信息安全影响评估报告表明存在高风险时。
          <br /><br />
          &nbsp;
          &nbsp;5、隐私权政策的任何更新将以标注更新时间的方式公布在我们网站上，除法律法规或监管规定另有强制性规定外，经调整或变更的内容一经通知或公布后的7日后生效。如您在隐私权政策调整或变更后继续使用我们提供的任一服务或访问我们相关网站的，我们相信这代表您已充分阅读、理解并接受修改后的隐私权政策并受其约束。
          <br /><br />
          &nbsp; &nbsp;八、如何联系我们 <br /><br />
          &nbsp;
          &nbsp;如您对本《隐私政策》或您个人信息的相关事宜有任何问题、意见或建议，请联系华夏云课堂。其中：个人信息保护邮箱：class@hxph.com.cn
          联系地址：北京市东城区香河园北里4号楼201室
          您还可以随时通过访问华夏云课堂在线客服系统等方式与我们联系。
          我们会对您提出的问题、意见或建议及时回复或处理。
          本《隐私政策》生效日期：2022年6 月22日
        </p>

        <span v-if="ifselect" class="span1"
          >欢迎您使用华夏云课堂平台及服务！</span
        >
        <br /><br />
        <!-- 服务协议 -->
        <p v-if="ifselect" class="textinfo">
          华夏云课堂（以下简称“本平台”或“华夏云课堂”）致力于为特殊儿童康复教育行业的从业者及特殊儿童家庭提供优质的在线学习与培训服务，以专业、专心、专注的态度，提供高质量、实用、有价值的知识和课程，为推动康复教育行业的发展做出更大的贡献。具体包括：
          <br /><br />
          <span class="titles">1. 协议条款的确认和接纳 </span><br /><br />
          1.1
          请您（以下亦称“用户”）仔细阅读本协议全部内容（特别是粗体标注的内容），并确认您已完全了解本协议之规定。
          本协议是您与华夏云课堂之间关于华夏云课堂服务的条款，内容包括本协议正文、本协议明确援引的其他协议及华夏云课堂已经发布的或将来可能发布的各类协议和规则。所有协议内容为本协议不可分割的组成部分，与本协议正文具有同等法律效力。除另行明确声明外，您使用华夏云课堂服务的行为将受本协议约束。
          <br /><br />1.2 如您选择点击“同意”本协议或者以其他方式开始使用华夏云课堂服务，即表示已经与华夏云课堂达成协议，并自愿接受本协议的所有内容。您确认，您应当具备中华人民共和国法律规定的与您行为相适应的民事行为能力，确保有能力对您使用华夏云课堂提供服务的一切行为独立承担责任。如您代表您的工作单位，请在点击“同意”前，确认您以获得充分授权。
          <br /><br />
          1.3 华夏云课堂有权根据法律规范及运营的合理需要，不断修改和完善本协议，并在华夏云课堂平台（hxclass.cn及华夏云课堂APP）公告。如您继续使用华夏云课堂服务，即意味着同意并自愿遵守修改后的协议条款，亦或您有权终止使用。您继续使用平台提供的任何服务，都表示您接受经修订的协议或规则。
          <br /><br />
          <span class="titles">2. 定义</span> <br /><br />
          2.1 您：指所有使用华夏云课堂web端及移动端用户。
          <br /><br />
          2.2华夏云课堂或者 华夏云课堂平台 （或华夏云课堂不时修改的其他名称）：为用户提供教学内容的生成、传播和消费服务，由华夏云课堂所有和经营的有关教育、学习等数字内容聚合、管理和分发的平台产品，包括但不限于web端（网址：hxclass.cn）及其各移动端（包括但不限于更新后的版本）。
          <br /><br />2.3 课程 ：指由华夏云课堂提供的，在华夏云课堂平台上陈列、展示、供用户观看、学习的视频、语音、文字、图片、链接等课程作品/制品。本定义下的课程包括免费课程与收费课程。
          <br /><br />2.3.1 免费课程 ：指华夏云课堂无偿提供的，供用户免费观看、使用的课程。
          <br /><br />2.3.2 收费课程 ：指华夏云课堂有偿提供的，供用户付费观看、使用的课程。
          <br /><br /><span class="titles">3. 账号注册与登录</span>
          <br /><br />3.1
          您可浏览华夏云课堂上的课程信息，如您希望观看、学习该课程，您需先登录您的账号，或注册华夏云课堂认可的账号并登录。您注册登录的账号是华夏云课堂确认您身份的唯一依据。
          <br /><br />3.2
          注册完成后，请您妥善保存有关账号和密码，并对该账号进行的所有活动和行为负责。如因您自身原因（包括但不限于转让账号、与他人共用、自己泄露等）或您所用计算机或其他终端产品感染病毒或木马，而导致账号密码泄漏、遗失或其他损失后果将由您独自承担。
          <br /><br />3.3 您确认：您是具备完全民事权利能力和完全民事行为能力的自然人、法人或其他组织，有能力对您使用华夏云课堂服务的一切行为独立承担责任。若您不具备前述主体资格，请在监护人的陪同下阅读本协议，并在取得监护人对您使用服务的行为，以及对本协议全部条款的同意之后，方可使用本服务；华夏云课堂在依据法律规定或本协议约定要求您承担责任时，有权向您的监护人追偿。
          <br /><br />3.4
          您在注册账号时承诺遵守法律法规、社会主义制度、国家利益、公民合法权益、公共秩序、社会道德风尚和信息真实性等七条底线，不得在账号注册资料中出现违法和不良信息。
          <br /><br />3.5
          华夏云课堂有权对您提供的账号注册资料进行审查，若发现或者收到举报确认注册资料存在不准确，不真实，或含有违法、不良信息，华夏云课堂有权不予注册，并保留终止您使用华夏云课堂平台的权利。若您以虚假信息骗取账号注册或账号注册资料存在侵权、违法和不良信息的，华夏云课堂有权采取通知限期改正、暂停使用、注销登记等措施。对于冒用其他机构或他人名义注册账号名称的，华夏云课堂有权注销该账号，并向政府主管部门进行报告。
          <br /><br />3.6 您若发现自身账号或密码被他人非法使用或有登录、使用异常情况的，应及时通知华夏云课堂，华夏云课堂将按照本协议或法律规定进行处理。
          <br /><br /><span class="titles">4. 数据</span>
          除非另有证明，华夏云课堂储存在其服务器上的数据是您使用华夏云课堂服务的唯一有效证据。
          <br /><br /><span class="titles">5. 华夏云课堂使用规则</span>
          <br /><br />5.1
          您不得利用华夏云课堂平台制作、复制、发布、传播如下法律、法规和政策禁止的内容：
          <br /><br />5.1.1 反对宪法所确定的基本原则的； <br /><br />5.1.2
          危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
          <br /><br />5.1.3 损害国家荣誉和利益的； <br /><br />5.1.4
          煽动民族仇恨、民族歧视，破坏民族团结的； <br /><br />5.1.5
          破坏国家宗教政策，宣扬邪教和封建迷信的； <br /><br />5.1.6
          散布谣言，扰乱社会秩序，破坏社会稳定的； <br /><br />5.1.7
          散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
          <br /><br />5.1.8 侮辱或者诽谤他人，侵害他人合法权益的；<br /><br />
          5.1.9 煽动非法集会、结社、游行、示威、聚众扰乱社会秩序；
          <br /><br />5.1.10 以非法民间组织名义活动的； <br /><br />5.1.11
          含有法律、法规和政策禁止的其他内容的信息。<br /><br />
          5.2
          您不得利用华夏云课堂平台制作、复制、发布、传播包括但不限于如下干扰平台的正常运营，以及侵犯其他主体或第三方合法权益的内容：
          <br /><br />
          5.2.1 广告、骚扰、垃圾信息的；<br /><br />
          5.2.2 涉及他人隐私、个人信息或资料的； <br /><br />
          5.2.3 侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的；<br /><br />
          5.2.4 强制、诱导其他用户关注、点击链接页面或分享信息的；<br /><br />
          <br /><br />5.2.5 虚构事实、隐瞒真相以误导、欺骗他人的；<br /><br />
          5.2.6
          未经华夏云课堂书面许可利用平台为第三方进行推广的（包括但不限于加入第三方链接、广告等行为）；
          <br /><br />
          5.2.7
          未经华夏云课堂书面许可使用插件、外挂或其他第三方工具、服务接入华夏云课堂平台和相关系统；
          <br /><br />
          5.2.8 利用平台从事任何违法犯罪活动的；<br /><br />
          5.2.9
          制作、发布与以上行为相关的方法、工具，或对此类方法、工具进行运营或传播，无论这些行为是否为商业目的；
          <br /><br />
          5.2.10 其他违反法律法规规定或干扰平台正常运营的行为。<br /><br />
          5.3
          您了解并同意，华夏云课堂有权应有权部门的要求，向其提供您提交给华夏云课堂或在使用华夏云课堂服务中存储于华夏云课堂服务器的必要信息。如您涉嫌侵犯他人合法权益，则华夏云课堂有权在初步判断涉嫌侵权行为存在的情况下，向权利人提供关于您的前述必要信息。
          <br /><br />
          5.4
          华夏云课堂有权视您违反本协议行为的严重程度，对您采取以下单项或多项措施：
          <br /><br />
          5.4.1 暂停/终止提供全部或部分服务； <br /><br />5.4.2
          删除违规内容；<br /><br />
          5.4.3 暂时/永久封禁账号； <br /><br />5.4.4
          其他华夏云课堂采取的合理措施；<br /><br />
          5.5
          如果您违反本协议的行为给华夏云课堂或其他第三方造成损失的，您应当对此承担法律责任。您承担法律责任的形式包括但不限于：对受到侵害者进行赔偿；以及在华夏云课堂首先承担了因您的行为导致的行政处罚或侵权损害赔偿责任后，您应给予华夏云课堂等额的赔偿。
          <br /><br />5.6
          如果您行使本协议规定的权利而购买/接受华夏云课堂以外的第三方提供的内容或服务，如因此发生纠纷的，您应向销售/提供该内容或服务的第三方主张权利，与华夏云课堂无关。
          <br /><br />5.7 对于在本平台上的内容,
          华夏云课堂不保证其适用性或满足您特定需求及目的进行任何明示或者默示的担保。在任何情况下,
          华夏云课堂均不对任何内容负责,
          包括但不限于任何内容发生任何错误或纰漏以及衍生的任何损失或损害。华夏云课堂有权（但无义务）自行拒绝或删除本平台上的任何内容。您使用上述内容,
          应自行承担风险。 <br /><br /><span class="titles">6. 服务收费</span>
          华夏云课堂平台可能涉及付费项目，付费项目包括但不限于购买华夏云课堂提供的付费课程以及其他增值服务服务。您使用付费项目相关事项请见附件1《华夏云课堂用户付费及退款协议》等相关协议或规则。
          <br /><br />
          <span class="titles">7. 隐私政策</span>
          尊重用户隐私是华夏云课堂的一项基本政策。华夏云课堂将按照有关要求收集、存储、使用、披露和保护您的个人信息，帮助您更好地保护您的个人信息。相关隐私政策详见《华夏云课堂用户隐私协议》。
          <br /><br />
          <span class="titles"> 8. 其他约定</span><br /><br />
          8.1
          华夏云课堂对不可抗力导致的损失不承担责任。本协议所指不可抗力包括但不限于：天灾、法律法规或政府指令的变更，因网络服务特性而特有的原因，例如境内外基础电信运营商的故障、计算机或互联网相关技术缺陷、互联网覆盖范围限制、计算机病毒、黑客攻击等因素，及其他合法范围内的不能预见、不能避免并不能克服的客观情况。
          <br /><br />
          8.2
          服务中止、中断及终止：华夏云课堂出于自身商业决策、执行行政/司法机关的命令、维护本服务的安全、维护其他用户的正常使用与数据安全、回应其他主体的侵权诉求等理由，或者华夏云课堂根据自身的判断，认为您的行为涉嫌违反本协议内容或涉嫌违反法律法规的规定的，则华夏云课堂有权变更、中止、中断或终止向您提供服务，且无须向您或任何第三方承担责任。
          <br /><br />
          8.3
          所有发给您的通知都可通过电子邮件、常规的信件或在华夏云课堂平台显著位置公告的方式进行传送。华夏云课堂将通过上述方法之一将消息传递给您，告知您协议的修改、服务变更、或其它重要事情。
          <br /><br />
          8.4
          所有权及知识产权：华夏云课堂平台上所有内容，包括但不限于文字、软件、声音、图片、录像、图表、网站架构、网站画面的安排、网页设计、华夏云课堂为您提供的课程及其他资料均由华夏云课堂或其他权利人依法拥有其知识产权，包括但不限于著作权、商标权、专利权等。非经华夏云课堂或其他权利人书面同意您不得擅自使用、修改、复制、录像、传播、改变、散布、发行或发表上述内容。如有违反，您同意承担由此给华夏云课堂或其他权利人造成的一切损失。
          <br /><br />
          8.5
          本协议适用中华人民共和国的法律。当本协议的任何内容与中华人民共和国法律相抵触时，应当以法律规定为准，同时相关内容将按法律规定进行修改或重新解释，而本协议其他部分的法律效力不变。
          <br /><br />
          8.6
          凡因本协议引起的或与本协议有关的任何争议，均可提交华夏云课堂所在地人民法院进行裁决，裁决结果对双方均有约束力。
          <br /><br />
          8.7
          华夏云课堂不行使、未能及时行使或者未充分行使本协议或者按照法律规定所享有的权利，不应被视为放弃该权利，也不影响华夏云课堂在将来行使该权利。
          <br /><br />
          8.8 本协议自发布之日起实施，并构成您和华夏云课堂之间的共识。<br /><br />
          8.9 如果您对本协议内容有任何疑问，请发送邮件至我们的客服邮箱：
          [class@hxph.com.cn]。
        </p>

        <!-- 付款协议 -->

        <div class="h1s" v-if="ifselect">
          <span class="span1" style="font-weight: 600"
            >附件1：华夏云课堂用户付费及退款协议</span
          >
        </div>
        <div v-if="ifselect">
          <p class="textinfo">
            <br /><br /><span class="titles">1. 协议条款的确认和接纳 </span>
            <br /><br />1.1
            欢迎使用华夏云课堂服务，为保障您的权益，请您在付费之前，详细阅读本协议的所有内容，特别是加粗部分。本协议内容包括协议正文、本协议下述明确援引的其他协议（包括但不限于《华夏云课堂用户服务协议》以及华夏云课堂已经发布的或将来可能发布的各类规则。所有协议内容为本协议不可分割的组成部分，与本协议正文具有同等法律效力。除另行明确声明外，您使用华夏云课堂服务的付费行为将受本协议约束。。
            <br /><br />
            1.2
            您应该具备中华人民共和国法律规定的与您行为相适应的民事行为能力。若您不具备签署与您行为相适应的民事行为能力，请确保您的监护人同意您的所有行为，在此情况下，您及您的监护人应依照法律规定承担因此而导致的一切后果。当您选择“点击同意”的或者以其他方式开始使用华夏云课堂付费服务的，即表示已经与华夏云课堂达成协议，并自愿接受本协议的所有内容。
            <br /><br />1.3
            华夏云课堂有权根据法律规范及运营的合理需要，不断修改和完善本协议，并在华夏云课堂平台（hxclass.cn）公告。如您继续使用华夏云课堂的付费服务，即意味着同意并自愿遵守修改后的协议条款，亦或您有权终止使用。
            <br /><br />
            2. 定义 <br /><br />2.1
            华夏云课堂或者华夏云课堂平台（或 华夏云课堂不时修改的其他名称 ）：为用户提供教学内容的生成、传播和消费服务，由华夏云课堂所有和经营的有关教育、学习等数字内容聚合、管理和分发的平台产品，包括但不限于web端（网站网址：hxclass.cn及其不时更换的域名）及其各移动端（包括但不限于更新后的版本）。
            <br /><br />2.2
            课程：指在华夏云课堂平台上陈列、展示，供用户观看、学习的视频、语音、文字、图片、链接等课程作品/制品。
            <br /><br />
            2.3 收费课程：指华夏云课堂有偿提供的，供用户付费观看、使用的课程。
            <br /><br />
            2.4付费服务：指按照本协议的约定，华夏云课堂在收到您支付的足额的费用后，向您提供对应收费课程的观看学习服务，以及华夏云课堂推出的相关增值服务。
            <br /><br />
            2.5
            华夏云课堂技术原因：指由平台技术故障引发的连续超过24小时课程无法正常观看、视频无法正常播放、PPT/PDF课程无法观看。如用户个人网络原因、观看设备故障、用户账号丢失等类原因不属于平台技术故障。
            <br /><br />
            3. 付费服务规范和用户规则 <br /><br />3.1
            华夏云课堂作为收费课程的售卖方
            ，为您提供华夏云课堂平台的收费课程，或者为您提供其他增值服务。<br /><br />
            3.2
            您可在华夏云课堂平台上浏览发布的收费课程与相关增值服务信息，如您希望有偿获得该部分服务，请您务必仔细了解本协议及确认相关具体信息（包括但不限于课程的内容、开课的时间、课程的期限、课程价格等），并根据操作提示，确认自己的账号、选择相关操作选项。华夏云课堂发布的付费服务可能对使用的时间、次数等做出一定限制；如超出限制范围，可能影响您继续使用该等服务，您需要再次付费购买。
            <br /><br />
            3.3
            您理解并同意：华夏云课堂提供付费服务实行先付款后使用的方式，您及时、足额、合法的支付所需的款项是您获得该等服务的前提。您有权选择使用华夏云课堂认可的支付方式，您理解并确认支付服务由华夏云课堂之外具备合法资质的第三方提供，该等支付服务的使用条件及规范由您与支付服务提供方确定，与华夏云课堂无关。
            <br /><br />
            3.4
            请您注意，在符合相关法律法规规定的情况下，华夏云课堂有权根据本协议规定对您购买的付费服务不予退款。您购买付费服务且开课后7天内非华夏云课堂技术原因不予退款。华夏云课堂技术原因是指：包括但不限于由技术故障引发的连续超过24小时课程无法正常观看、视频无法正常播放、PPT/PDF课程无法观看、重复购买课程等；如因您的个人网络原因、观看设备故障、账号丢失等类原因不属于平台技术故障。除非法律法规另有规定或本协议另有约定外，您购买付费服务超过7天的，一律不予退款。参加特价优惠或促销活动的退款规则以具体活动规则为准。以上规则适用于华夏云课堂安卓版以及PC客户端、网页版。
            <br /><br />
            3.5
            华夏云课堂对所提供的付费服务的适用性或满足您特定需求及目的不承担任何明示或者默示的担保。请您在付费前确认自身的需求，同时仔细阅读相关课程的详情说明。
            <br /><br />
            3.6
            您知悉并同意，您购买的付费服务仅限于您自行使用，您无权对购买的收费课程及相关增值服务服务进行出售、转让、许可、传播或以其他方式使除您自己以外的第三方（包括但不限于自然人、法人或其他组织）使用。若您违反本条规定，则华夏云课堂有权视情况采取如下措施而无需承担任何责任：
            <br /><br />
            3.6.1 取消您继续使用该付费服务的权利；<br /><br />
            3.6.2 限制、冻结、终止您的账号； <br /><br />
            3.6.3 要求您退还通过出售、转让、许可等其他方式取得的收益；
            <br /><br />3.6.4 其他华夏云课堂认为可以采取的相关措施。<br /><br />
            3.7
            您了解并同意，华夏云课堂可能会基于自身原因（包括但不限于：更新课程内容、改进课程安排）不定期的对付费服务内容或功能进行更新而无需经过您的事先同意。
            <br /><br />
            3.8
            您通过华夏云课堂平台支付的款项由华夏云课堂收取，若您需要获取发票等相关凭据，可以在线开具，或联系华夏云课堂及相关渠道商来获取凭证。
            <br /><br />
            3.9
            您应保管好自己的账号和密码，如因您未保管好自己的账号和密码而对自己、华夏云课堂或第三方造成损害的，您将负全部责任。另外，您应对您账号中的所有活动和事件负全责。您可随时改变账号和密码。您同意若发现有非法使用您的账号或出现安全漏洞的情况，立即通告华夏云课堂，华夏云课堂将会尽力予以妥善解决。
            <br /><br />
            3.10
            如果华夏云课堂发现因平台系统故障等原因 （包括但不限于商品名称、价格或数量等关键信息存在错误等）导致的充值/购买结果处理错误，无论有利于华夏云课堂还是有利于您，华夏云课堂都有权在以平台公告或其他合理方式通知您后纠正该错误（包括但不限于取消交易订单等）。
            <br /><br />
            4. 服务终止 <br /><br />
            4.1华夏云课堂根据自身商业决策等原因可能会选择终止华夏云课堂的付费服务。如有此等情形发生，华夏云课堂会采取公告或其他合适的方式通知您，并以您购买收费课程但未实际使用（即您付费购买收费课程后尚未实际点击观看课程之情形）的费用为限，将上述费用返还至您的购买账户中。除法律有明确规定的情况外，华夏云课堂有权不经您申请并直接为您办理退款。
            <br /><br />4.2经国家行政或司法机关的生效法律文书确认您存在违法或侵权行为的，或者华夏云课堂根据自身的判断、认为您的行为涉嫌违反本协议内容的，或涉嫌违反法律法规的规定的，则华夏云课堂有权中止、中断或终止向您提供付费服务，并无需向您退还任何费用以及不承担任何责任。
            <br /><br />5. 用户退款协议 <br /><br />5.1
            基础课程及系统培训课程：用户购买课程之日起7天内非华夏云课堂技术原因不予退款。用户购买超过7天后一律不予退款。
            <br /><br />
            5.2
            其他课程：购买后7日内且未开课，可无条件退款。其余情况，非平台技术原因不予退款。
            <br /><br />
            5.3
            参加特价优惠或促销活动课程：参加特价优惠或促销活动的退款规则以具体活动规则为准，如无具体活动规则，则以上述规则为准。
            <br /><br />
            5.4
            用户存在违规或侵权行为：若用户在使用华夏云课堂账号或购买课程过程中违反与华夏云课堂达成的相关协议，或者用户利用华夏云课堂账号从事违法行为、侵犯第三方合法权益的行为，华夏云课堂采取封禁/冻结账号措施后一律不予退款。
            <br /><br />
            6. 其他约定 <br /><br />6.1
            除非另有证明，华夏云课堂储存在其服务器上的数据是您使用华夏云课堂付费服务的唯一有效证据。
            <br /><br />
            6.2
            不可抗力：华夏云课堂对不可抗力导致的损失不承担责任。本协议所指不可抗力包括但不限于：天灾、法律法规或政府指令的变更，因网络服务特性而特有的原因，例如境内外基础电信运营商的故障、计算机或互联网相关技术缺陷、互联网覆盖范围限制、计算机病毒、黑客攻击等因素，及其他合法范围内的不能预见、不能避免并不能克服的客观情况。
            <br /><br />
            6.3
            通知：所有发给您的通知都可通过电子邮件、常规的信件或在网站显著位置公告的方式进行传送。华夏云课堂将通过上述方法之一将消息传递给您，告知您本协议的修改、付费服务变更、或其它重要事情。
            <br /><br />
            6.4 本协议自发布之日起实施，并构成您和华夏云课堂之间的共识。
            <br /><br />6.5
            本协议适用中华人民共和国的法律。当本协议的任何内容与中华人民共和国法律相抵触时，应当以法律规定为准，同时相关内容将按法律规定进行修改或重新解释，而本协议其他部分的法律效力不变。
            <br /><br />
            6.6
            凡因本协议引起的或与本协议有关的任何争议，均应提交华夏云课堂所在地人民法院进行裁决，裁决结果对双方均有约束力。
            <br /><br />
            6.7 如果您对本协议内容有任何疑问，请发送邮件至我们的客服邮箱：
            [class@hxph.com.cn]
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // urlimg: require(""),
      ifselect: false,
      titletxt: "华夏云课堂隐私协议",
    };
  },
  methods: {
    selbacolor(e) {
      if (e == 1) {
        this.ifselect = false;
        this.titletxt = "华夏云课堂隐私协议";
        location.search = "?sel=2";
      } else {
        this.ifselect = true;
        this.titletxt = "华夏云课堂服务协议";
        location.search = "?sel=1";
      }
    },
  },
  created() {
    if (this.$route.query.sel == 2) {
      this.ifselect = false;
      this.titletxt = "华夏云课堂隐私协议";
    } else {
      this.ifselect = true;
      this.titletxt = "华夏云课堂服务协议";
    }
  },
};
</script>

<style lang="less" scoped>
.infobox {
  width: 1200px;
  margin: 50px auto;
  overflow: hidden;
  display: flex;
  background-color: #f6f6fc;
  .leftbox {
    margin-top: 60px;
    width: 300px;
    background: #ffffff;
    .lefttitle {
      font-size: 20px;
      font-weight: bold;
      color: #ffffff;
      line-height: 30px;
      background: #398fa7;
      text-align: center;
      width: 100%;
      padding: 34px 0;
      height: 97px;
      overflow: hidden;
      margin-bottom: 20px;
      img {
        float: left;
      }
    }

    .leftname {
      width: 300px;
      height: 70px;
      display: flex;
      align-items: center;
      cursor: pointer;
      span {
        font-size: 18px;
        font-family: PingFangMedium;
        font-weight: 500;
        color: #787d82;
        margin-left: 116px;
      }
    }

    .seltxt {
      background: rgba(21, 183, 221, 0.1);
      span {
        margin-left: 108px !important;
        color: #15b7dd;
        font-weight: 600;
      }
      &:before {
        content: "";
        width: 8px;
        height: 70px;
        background: #15b7dd;
        display: block;
      }
    }
  }
  .rightbox {
    margin-top: 60px;
    margin-left: 24px;
    width: 1120px;
    padding: 48px 40px;
    background: #ffffff;
  }
}
.h1s {
  text-align: center;
  line-height: 22px;
  font-family: PingFangMedium;
  font-size: 30px;
  h1 {
    font-weight: 600;
  }
}
.span1 {
  display: block;
  font-size: 18px;
  font-family: PingFangMedium;
  font-weight: 500;
  color: #333333;
  line-height: 27px;
}
.span2 {
  display: block;
  font-size: 18px;
  font-family: PingFangBold;
  font-weight: 500;
  color: #333333;
  line-height: 27px;
}
.span3 {
  display: block;
  font-size: 18px;
  font-family: PingFangMedium;
  font-weight: 500;
  color: #333333;
  line-height: 27px;
}
.textinfo {
  font-size: 16px;
  font-family: PingFangMedium;
  color: #333333;
  line-height: 24px;
}
.h2s {
  font-size: 24px;
  font-family: Source Han Sans CN-Bold;
  font-weight: bold;
  color: #333333;
}
.titles {
  font-size: 16px;
  font-family: Source Han Sans CN-Medium;
  font-weight: 600;
  color: #333333;
  line-height: 24px;
}

.righttxt {
  text-align: right;
  line-height: 18px;
  display: block;
  font-family: PingFang SC;
  font-size: 12px;
}
</style>

